import React, { useContext } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import useTranslations from "hooks/useTranslations";
import Layout from "components/layout";
import Card from "components/card";
import SEO from "components/seo";
import NotFound from "components/NotFound";
import { rhythm, scale } from "utils/typography";
import { mq } from "utils/helper";
import { LocaleContext } from "context/locale-context";

function LatestInves({ nodes, locale }) {
  const reports = nodes.edges;
  const isRTL = locale === "ar";
  return (
    <div
      css={{
        maxWidth: rhythm(50),
        margin: "auto",
        display: "grid",
        justifyItems: "center",
        gridTemplateColumns: "repeat(4, 1fr)",
        gridRowGap: rhythm(3),
        gridColumnGap: rhythm(2),
        paddingBottom: rhythm(1),
        direction: isRTL ? "rtl" : "ltr",
        [mq[0]]: {
          display: "block",
        },
        marginBottom: rhythm(4),
      }}
    >
      {reports.map(({ node }) => (
        <Card node={node} key={node.slug} locale={locale} />
      ))}
    </div>
  );
}

function NotFoundPage({ data }) {
  const tr = useTranslations();
  const siteTitle = data.site.siteMetadata.title;
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  return (
    <Layout title={siteTitle}>
      <SEO metadata={{ title: "404: Not Found" }} />
      <NotFound />

      <div
        css={{
          ...scale(0.6),
          fontWeight: "500",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: rhythm(3),
          [mq[1]]: {
            ...scale(0.4),
            display: "block",
            textAlign: "center",
          },
        }}
      >
        <p>{tr("Nothing found here.")}</p>
        <p>{tr("But you can still read our latest investigations.")}</p>
      </div>

      <LatestInves nodes={data.allMdx} locale={locale} />
    </Layout>
  );
}

NotFoundPage.propTypes = {
  data: PropTypes.object,
};

export default NotFoundPage;

export const pageQuery = graphql`
  query ReportsOn4042 {
    site {
      siteMetadata {
        title
      }
    }
    allMdx: allStrapiInvestigation {
      edges {
        node {
          slug
          locale
          title
          date
          desc
          dataset
          type
          image {
            url
          }
        }
      }
    }
  }
`;
