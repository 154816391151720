import React, { useContext } from "react";
import { mq } from "utils/helper";
import { rhythm, scale } from "utils/typography";
import styled from "@emotion/styled";
import { ThemeContext } from "context/theme-context";


const H1 = styled.h1(({ colors }) => ({
  ...scale(2.5),
  fontWeight: "100",
  color: colors.primary,
  marginBottom: 0,
  [mq[1]]: {
    ...scale(2)
  }
}));

function NotFound() {
  const { colors } = useContext(ThemeContext);
  return (
    <div
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: rhythm(4)
      }}
    >
      <H1
        css={{
          marginRight: rhythm(1),
          [mq[1]]: {
            marginLeft: rhythm(1)
          }
        }}
        colors={colors}
      >
        4
      </H1>
      <img
        src="/assets/header-logo.svg"
        alt="logo"
        css={{ width: rhythm(7) }}
      />
      <H1
        css={{
          marginLeft: rhythm(1),
          [mq[1]]: {
            marginRight: rhythm(1)
          }
        }}
        colors={colors}
      >
        4
      </H1>
    </div>
  );
}

export default NotFound;
